$(document).ready(function() {

    //
    // Lightbox
    //
    function lightboxWinHeight(Uid) {

        var Id = Uid;
        var Height = $('div#lightbox-c' + Id).outerHeight(true);
        //console.log(Height);

        return Height;

    }
    function lightboxHeaderHeight(Uid) {

        var Id = Uid;
        var Height = $('div#lightbox-c' + Id).find('div.lightbox-header').first().outerHeight(true);
        //console.log(Height);

        return Height;
    }
    function lightboxFigcapureHeight(Uid) {

        var Id = Uid;
        var Height = $('div#lightbox-c' + Id).find('figcaption').first().outerHeight(true);
        //console.log(Height);

        return Height;
    }
    function lightboxImageWrapperWidth(Uid) {

        var Id = Uid;
        var Width = $('div#lightbox-c' + Id).find('img#lightbox-image' + Id).width();
        //console.log(Width);

        return Width;
    }
    function setLightboxImageMaxHeight(Uid){

        var Id = Uid;
        var vHeight = lightboxWinHeight(Id);
        var hHeight = lightboxHeaderHeight(Id);
        var cHeight = lightboxFigcapureHeight(Id);
        var ImageMaxHeight = vHeight - (hHeight + cHeight);
        var Image = $('img#lightbox-image' + Id);
        var Placeholder = 40;

        // Maximale Bildhöhe einstellen
        Image.css('max-height', (ImageMaxHeight - Placeholder) + 'px');

    }
    function setLightboxImageCaptionWidth(Uid){

        var Id = Uid;
        var ImageWrapperWidth = lightboxImageWrapperWidth(Id);
        var Figcaption = $('div#lightbox-c' + Id).find('figcaption > span').first();
        //console.log(ImageWrapperWidth);

        Figcaption.css('width', ImageWrapperWidth + 'px');

    }
    function placeFigureVerticalCenter(Uid){

        var Id = Uid;
        var Figure = $('div#lightbox-c' + Id).find('figure').first();
        var FigureHeight = Figure.outerHeight(true);
        var FigureWrapperHeight = Figure.parent().outerHeight(true);
        var FigureTop = (FigureWrapperHeight/2) - (FigureHeight/2);

        Figure.css('margin-top', FigureTop + 'px');

    }
    function setLightbox(){

        // Lightbox öffnen
        $( ".bund-open-lightbox" ).each(function() {

            $(this).on("click keypress", function (event) {

                if (event.type === "click" || event.type === "keypress") {
                    event.preventDefault();
                    //console.log('tastaturclick ' + event.which);

                    if (event.which == 32 || event.which == 13 || event.which == 1) {
                        event.preventDefault();

                        var Button = $(this);
                        var Id = Button.attr('data-uid');
                        var Modal = $('div#lightbox-c' + Id);
                        var ModalHeader = $('div#lightbox-h' + Id);
                        var ModalCloseButton = $('button#close-lightbox-c' + Id);
                        var Body = $('body');

                        // Modal anzeigen
                        if(Modal.hasClass('hidden')){
                            Modal.removeClass('hidden');
                            Body.addClass('overflow-y-hidden');
                            //var PosY = $(document).scrollTop(); //Scrollposition
                            ModalHeader.focus();
                            setLightboxImageMaxHeight(Id);
                            setLightboxImageCaptionWidth(Id);
                            placeFigureVerticalCenter(Id);
                            setLightboxImageMaxHeight(Id);//Bildhöhe nochmals anpassen, falls die Textelementhöhe sich ändert
                            $(window).on('resize', function () {
                                setLightboxImageMaxHeight(Id);
                                setLightboxImageCaptionWidth(Id);
                                placeFigureVerticalCenter(Id);
                                setLightboxImageMaxHeight(Id);//Bildhöhe nochmals anpassen, falls die Textelementhöhe sich ändert
                            });
                        }
                    }
                }

            });
        });

        // Lightbox mit Button schließen
        $( ".close-lightbox-button" ).each(function() {

            $(this).on("click", function (event) {
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    event.preventDefault();

                    var CloseButton = $(this);
                    var CloseButtonId = CloseButton.attr('data-uid');
                    var CloseButtonModal = $('div#lightbox-c' + CloseButtonId);
                    var CloseButtonBody = $('body');
                    var OpenButton = $('a#open-lightbox-c' + CloseButtonId);

                    // Modal schließen
                    if(CloseButtonModal.hasClass('hidden')===false){
                        CloseButtonModal.addClass('hidden');
                        CloseButtonBody.removeClass('overflow-y-hidden');
                        //$('html, body').scrollTop(PosY); //Scrollposition wieder herstellen
                        OpenButton.focus();
                    }
                }
            });
        });

        // Lightbox mit ESC schließen
        $(document).keydown(function(eventa) {

            var ModalDialoge = $('div.lightbox-modal');
            var Body = $('body');

            ModalDialoge.each(function() {

                var Modal = $(this);
                var OpenButton = $('a#open-lightbox-c' + Modal.attr('data-uid'));

                if(Modal.hasClass('hidden')===false){
                    // 27=ESC
                    if (eventa.keyCode == 27) {
                        Modal.addClass('hidden');
                        Body.removeClass('overflow-y-hidden');
                        OpenButton.focus();
                    }
                }

            });

        });

        // Tab
        $(document).keydown(function(eventl) {

            var ModalDialoge = $('div.lightbox-modal');

            ModalDialoge.each(function () {

                var Modal = $(this);
                var Id = Modal.attr('data-uid');
                var LastModalTabLink = Modal.find('button#close-lightbox-c' + Id);
                var FirstModalTabLink = Modal.find('div#lightbox-h' + Id);

                // Tab vor, springe von letztem Tab-Element im Modal, zu erstem Tab-Element im Modal
                LastModalTabLink.keydown(function(eventlforwardtab) {
                    if (!eventlforwardtab.shiftKey && eventlforwardtab.keyCode == 9) {
                        eventlforwardtab.preventDefault();
                        FirstModalTabLink.focus();
                    }
                    // Tab zurück
                    if (eventlforwardtab.shiftKey && eventlforwardtab.keyCode == 9) {
                        eventlforwardtab.preventDefault();
                        FirstModalTabLink.focus();
                    }
                });

                // Tab zurück
                FirstModalTabLink.keydown(function(eventlbackwardtab) {
                    // Tab zurück
                    if (eventlbackwardtab.shiftKey && eventlbackwardtab.keyCode == 9) {
                        eventlbackwardtab.preventDefault();
                        LastModalTabLink.focus();
                    }
                });

                // Fallback wenn nichts fokussiert ist
                Modal.keydown(function(eventlmbackwardtab) {
                    if (!FirstModalTabLink.is(":focus")) {
                        if (!LastModalTabLink.is(":focus")) {
                            if (eventlmbackwardtab.keyCode == 9) {
                                eventlmbackwardtab.preventDefault();
                                FirstModalTabLink.focus();
                            }
                        }
                    }
                });

            });

        });


    }
    setLightbox();

});